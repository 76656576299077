
import { defineComponent, ref, onMounted, onUnmounted, nextTick } from 'vue';
import { MiniArea } from '../../../analysis/components/charts';
import './index.less';

function fixedZero(val: number) {
  return val * 1 < 10 ? `0${val}` : val;
}

function getActiveData() {
  const activeData = [];
  for (let i = 0; i < 24; i += 1) {
    activeData.push({
      x: `${fixedZero(i)}:00`,
      y: Math.floor(Math.random() * 200) + i * 50,
    });
  }
  return activeData;
}

function useLoopData() {
  let timer: any = null;
  const requestRef = ref<number | undefined>(undefined);
  const activeData = ref<any>(getActiveData());

  const loopData = () => {
    requestRef.value = requestAnimationFrame(() => {
      timer = setTimeout(() => {
        activeData.value = getActiveData();
        nextTick(() => {
          loopData();
        });
      }, 1000);
    });
  };

  const clear = () => {
    clearTimeout(timer);
    if (requestRef.value) {
      cancelAnimationFrame(requestRef.value);
    }
  };

  onMounted(() => {
    loopData();

    // cancel
    onUnmounted(() => {
      clear();
    });
  });

  return {
    clear,
    activeData,
  };
}

export default defineComponent({
  name: 'ActiveChart',
  setup() {
    const { activeData } = useLoopData();

    return {
      activeData,
    };
  },
  components: {
    MiniArea,
  },
});
